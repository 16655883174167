import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import SearchIcon from "@mui/icons-material/Search";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";

import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setArchived } from "../../../../Redux/Actions/actions";
import "../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import "../styles/admin-data-grid.css";
import "../styles/admin-requests.css";

// Custom Toolbar component for the Admin Data Grid
export const CustomToolbar = ({
  value,
  handleSearch,
  rows,
  setRows,
  rowData,
  setSearchValue,
  handleClearInput,
  initialRows,
}) => {
  const { hideArchived, setHideArchived } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const handleHideArchived = (e) => {
    if (e.target.checked && hideArchived === false) {
      setHideArchived(true);
      dispatch(setArchived(true));
      let filterRows = rows.filter((row) => !row.archived);
      setRows(filterRows);
    } else {
      setRows(initialRows.current);
      setHideArchived(false);
      dispatch(setArchived(false));
    }
  };
  return (
    <div className="mainToolbarContainer">
      <GridToolbarContainer className="toolbarLeft" id="toolbar-left">
        <GridToolbarFilterButton className="toolbarFilter toolbar-filter-button" />
      </GridToolbarContainer>
      <div className="toolbarRight adminToolbarRight">
        <FormControlLabel
          className="switch-formControlLabel"
          id="switch-form-control-label"
          classes={{ label: "switch-form-label" }}
          control={
            <Tooltip
              id="switch-tooltip"
              title="If Switch is turned on, the table below will filter out all projects with a request status of 'Archived'"
              placement="top"
              arrow
              classes={{
                tooltip: "archive-switch-tooltip",
                popper: "archive-switch-popper",
                arrow: "archive-switch-arrow",
                tooltipPlacementTop: "archive-switch-top",
              }}
            >
              <Switch
                className="admin-archive-switch"
                classes={{
                  root: "archive-switch-root",
                  switchBase: "archive-switchBase",
                  input: "archive-switch-base-input",
                  checked: "archive-switch-checked",
                  thumb: "archive-switch-thumb",
                  track: "archive-switch-track",
                  colorPrimary: "archive-switch-color-primary",
                }}
                checked={hideArchived}
                onChange={handleHideArchived}
              />
            </Tooltip>
          }
          label={`${!hideArchived ? "Hide" : "Show"} Archived Requests`}
          labelPlacement="start"
        />
        <FormControl
          variant="outlined"
          id={"search-form-control"}
          classes={{ root: "search-form-control-root" }}
        >
          <OutlinedInput
            type="text"
            id="search-field"
            label="Search Table"
            value={value}
            onChange={handleSearch}
            classes={{
              root: "search-field-root",
              notchedOutline: "search-field-notchedOutline",
              input: "search-field-input",
            }}
            autoComplete="off"
            startAdornment={
              <SearchIcon className="search-icon" aria-hidden={true} />
            }
            endAdornment={
              <IconButton
                id="clear-search-btn"
                onClick={(e) =>
                  handleClearInput(
                    e,
                    setSearchValue,
                    setRows,
                    initialRows,
                    hideArchived,
                  )
                }
                classes={{ root: "clear-search-btn-root" }}
              >
                <CloseIcon className="clear-search-icon" />
              </IconButton>
            }
          />
        </FormControl>
      </div>
    </div>
  );
};

// Email/Invite Existing User Popover Column
export function EmailUsersPopover({ params, asyncSendEmail }) {
  const { users } = params.row;
  const { setEmailAnchor, openEmail, setOpenEmail, setCurrEmailRow } =
    useContext(DashboardContext);

  const [searchVal, setSearchVal] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  // Email user event handler
  const handleEmailUser = async (e, user) => {
    e.preventDefault();
    const clientId = params.row.clientId;
    const projectId = params.id;

    await asyncSendEmail({ clientId, projectId, user, location: "form" });
  };

  // Search users event handler
  const handleSearchForUser = (e) => {
    const val = e.target.value;

    setSearchVal(val);
    // If users.length is 0, return
    if (users.length === 0) return;

    // If val is empty, set filteredUsers to users
    if (val === "") {
      setFilteredUsers(users);
      return;
    }

    // Filter users by name or email, compare values as lower case
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(val.toLowerCase()) ||
        user.email.toLowerCase().includes(val.toLowerCase()),
    );

    // Set filteredUsers to filtered
    setFilteredUsers(filtered);
  };

  return (
    <Modal
      id={"emailUserModal"}
      key={params.id}
      open={openEmail}
      onClose={() => {
        setEmailAnchor(null);
        setCurrEmailRow(null);
        setOpenEmail(false);
      }}
    >
      <div id={"emailUserContainer"}>
        <h1 id={"emailUserHeading"}>Invite Existing Users</h1>
        <div id={"emailUserSearch"}>
          <FormControl variant="outlined" id={"search-user-form"}>
            <InputLabel
              id={"search-user-label"}
              classes={{ shrink: "search-user-label-shrink" }}
              htmlFor={"search-user-field"}
            >
              Search For User By Name or Email
            </InputLabel>
            <OutlinedInput
              type="text"
              id={"search-user-field"}
              label="Search For User"
              value={searchVal}
              onChange={handleSearchForUser}
              classes={{
                root: "search-user-field-root",
                notchedOutline: "search-user-field-notchedOutline",
                input: "search-user-field-input",
              }}
              startAdornment={
                <SearchIcon className={"search-icon"} aria-hidden={true} />
              }
              endAdornment={
                <IconButton
                  id={"clear-search-btn"}
                  onClick={() => {
                    setSearchVal("");
                    setFilteredUsers(users);
                  }}
                >
                  <CloseIcon className={"clear-search-icon"} />
                </IconButton>
              }
            />
            <FormHelperText id={"search-user-helper-text"}>
              **Case Sensitive**
            </FormHelperText>
          </FormControl>
        </div>
        <div id={"emailUserList"}>
          {users.length > 0
            ? filteredUsers.map((user, index) => {
                return (
                  <div key={index} id={"emailUserListItem"}>
                    <div id={"userIconContainer"}>
                      <AccountCircleIcon
                        className={"userIcon"}
                        id={"userIcon"}
                      />
                      <p id={"userNameP"}>{user.name}</p>
                    </div>
                    <p id={"emailUserP"}>{user.email}</p>
                    <Button
                      variant="contained"
                      id={"inviteUserButton"}
                      className={"inviteUserButton"}
                      onClick={(e) => handleEmailUser(e, user)}
                    >
                      Invite
                    </Button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Modal>
  );
}

// Project Contacts Popover Column
export function ContactsPopover({ params }) {
  const poc = params.value.poc;
  const submitter = params.value.submitter;

  const {
    setContactsAnchor,
    openContacts,
    setOpenContacts,
    setCurrContactsRow,
  } = useContext(DashboardContext);

  return (
    <Modal
      id={"contactsModal"}
      key={params.row.id}
      open={openContacts}
      onClose={() => {
        setContactsAnchor(null);
        setCurrContactsRow(null);
        setOpenContacts(false);
      }}
    >
      <div id={"contactsContainer"}>
        <h1 id={"contactsHeading"}>Project Contacts</h1>
        <div className={"contacts"}>
          {submitter && submitter.name && (
            <div className={"contactsInfoContainer"}>
              <h2 className={"contactsSubHeading"}>Submitter</h2>
              <div className={"contactsInfo"}>
                <div className={"contactDetailsContainer"}>
                  <AccountCircleIcon className={"contactDetailsIcon"} />
                  <p className={"contactDetails"}>{submitter.name}</p>
                </div>
                <div className={"contactDetailsContainer"}>
                  <MailRoundedIcon className={"contactDetailsIcon"} />
                  <a
                    className={"contactDetails"}
                    href={`mailto:${submitter.email}`}
                  >
                    {submitter.email}
                  </a>
                </div>
                <div className={"contactDetailsContainer"}>
                  <PhoneEnabledRoundedIcon className={"contactDetailsIcon"} />
                  <a
                    className={"contactDetails"}
                    href={`tel:1+${submitter.phone.split("-").join("")}`}
                  >
                    {submitter.phone}
                  </a>
                </div>
                {submitter.title && submitter.title !== "" && (
                  <div className={"contactDetailsContainer"}>
                    <WorkRoundedIcon className={"contactDetailsIcon"} />
                    <p className={"contactDetails"}>{submitter.title}</p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={"contactsInfoContainer"}>
            <h2 className={"contactsSubHeading"}>Point of Contact</h2>
            <div className={"contactsInfo"}>
              <div className={"contactDetailsContainer"}>
                <AccountCircleIcon className={"contactDetailsIcon"} />
                <p className={"contactDetails"}>{poc.name}</p>
              </div>
              <div className={"contactDetailsContainer"}>
                <MailRoundedIcon className={"contactDetailsIcon"} />
                <a className={"contactDetails"} href={`mailto:${poc.email}`}>
                  {poc.email}
                </a>
              </div>
              <div className={"contactDetailsContainer"}>
                <PhoneEnabledRoundedIcon className={"contactDetailsIcon"} />
                <a
                  className={"contactDetails"}
                  href={`tel:1+${poc.phone.split("-").join("")}`}
                >
                  {poc.phone}
                </a>
              </div>
              <div className={"contactDetailsContainer"}>
                <WorkRoundedIcon className={"contactDetailsIcon"} />
                <p className={"contactDetails"}>{poc.title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// Project Completion Percentage column
