import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to update archived projects
const updateArchivedProjects = async (data) => {
  const { projectId } = data;
  try {
    const res = await axios.patch(`/api/toggle-archive-project/${projectId}/`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    console.log("ERROR: ", error);
  }
};

// Custom hook to update archived projects
export const useArchiveProjects = (enqueueSnackbar, rows, setRows) => {
  const { queryClient } = useContext(DashboardContext);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: updateArchivedProjects,
    onMutate: async (variables) => {
      const { projectId, archiveValue } = variables;

      // Snapshot the previous value
      const previousRows = rows;

      // Optimistically update the cache
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === projectId ? { ...row, archived: archiveValue } : row,
        ),
      );

      return { previousRows: previousRows };
    },
    onSuccess: (data, variables) => {
      const { archived, archivedProjects, projectName } = data;
      // Sync Redux store
      dispatch(
        updateUser({
          archivedProjects: archivedProjects,
        }),
      );
      // Show a success snackbar
      enqueueSnackbar(
        archived
          ? `Successfully archived ${projectName}`
          : `Successfully unarchived ${projectName}`,
        {
          variant: "success",
          autoHideDuration: 2500,
        },
      );
    },
    onError: (error, variables, context) => {
      console.log(error);
      // Roll back to the previous value
      setRows(() => [...context.previousRows]);
      // Show an error snackbar
      enqueueSnackbar(
        `Error updating the archive status for ${variables.projectName}`,
        {
          variant: "error",
          autoHideDuration: 4000,
        },
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["adminRows"] });
    },
  });
};
